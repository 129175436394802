import React from "react";
import cx from "classnames";
import { HiLockClosed as LockClosedIcon } from "react-icons/hi2";

import { Input } from "~src/ui/input";
import { Button } from "~src/ui/button";
import { HEADING_1, LINK_COLOR, TEXT_COLOR } from "~src/ui/classNames";
import { useForm } from "react-hook-form";
import { graphql } from "~src/gql";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

type ForgotPasswordFormData = {
  username: string;
};

const forgotPasswordMutationDocument = graphql(`
  mutation forgotPassword($username: String!) {
    sendResetPasswordEmail(username: $username) {
      ok
    }
  }
`);

export function ForgotPassword() {
  // const apolloClient = useApolloClient();
  const { register, getValues } = useForm<ForgotPasswordFormData>();
  const [sendEmail] = useMutation(forgotPasswordMutationDocument);
  const onSubmit = React.useCallback(() => {
    const { username } = getValues();
    sendEmail({ variables: { username } }).then(() => {
      toast.info(
        "An email with instructions to reset password has been sent to your mailbox."
      );
    });
  }, [getValues, sendEmail]);
  return (
    <>
      <div>
        <h2 className={cx("mt-6 text-center", HEADING_1)}>Reset password</h2>
        <p className={cx("mt-2 text-center text-sm ", TEXT_COLOR)}>
          Or{" "}
          <Link to="/auth" className={cx("font-medium", LINK_COLOR)}>
            sign in
          </Link>
        </p>
      </div>
      <form className="mt-8 space-y-6">
        <div className="-space-y-px rounded-md shadow-sm">
          <div>
            <label
              htmlFor="user-identifier"
              className="sr-only dark:text-white"
            >
              Email
            </label>
            <Input
              id="user-identifier"
              type="email"
              required
              className="relative block w-full mb-2"
              placeholder="Email"
              {...register("username")}
            />
          </div>
        </div>

        <div>
          <Button
            type="button"
            className="relative flex w-full"
            onClick={onSubmit}
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <LockClosedIcon
                size={20}
                className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                aria-hidden="true"
              />
            </span>
            Reset password
          </Button>
        </div>
      </form>
    </>
  );
}
