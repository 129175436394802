import { graphql } from "~src/gql";
import { ArchiveOrDeletePair } from "~src/gql/graphql";
import { apolloClient } from "~src/lib/apollo";

const ArchiveOrDeleteMutationDocument = graphql(`
  mutation IMAPMigrationTaskConfigArchiveOrDelete(
    $pairs: [ArchiveOrDeletePair!]!
  ) {
    toggleNodesArchiveStatusOrDeleteDraft(pairs: $pairs) {
      changedNodes {
        __typename
        id
        archived # for some reason apollo only update local stuff when this field in selected here instead of in the IDObjectNode interface
        ... on IDObjectNode {
          id
          archived
        }
      }
      deletedIds
    }
  }
`);

export function archiveOrDelete(pairs: ArchiveOrDeletePair[]) {
  apolloClient
    .mutate({
      mutation: ArchiveOrDeleteMutationDocument,
      variables: { pairs },
    })
    .then((res) => {
      const deletedIds =
        res.data?.toggleNodesArchiveStatusOrDeleteDraft.deletedIds || [];
      const changedNodes =
        res.data?.toggleNodesArchiveStatusOrDeleteDraft.changedNodes || [];
      apolloClient.cache.batch({
        update: (cache) => {
          for (const node of changedNodes) {
            cache.evict({ id: node.id });
          }
          for (const id of deletedIds) {
            cache.evict({ id, broadcast: false });
          }
        },
      });
    });
}
