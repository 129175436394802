import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { graphql, useFragment } from "~/src/gql";
import { AuthQuery, IdentityViewerFragment } from "~/src/gql/graphql";
import { IdentityViewerFrag } from "~src/commonFragments";

type IdentityContextType = AuthQuery & {
  viewer: IdentityViewerFragment;
  loading: boolean;
} & {
  organizationId: string;
} & {
  setOrganizationId: (v: string) => void;
  logout?: () => void;
};

const emptyContext: IdentityContextType = {
  loading: true,
  viewer: {
    id: "viewer",
  },
  organizationId: "",
  setOrganizationId: () => undefined,
};

const IdentityContext = React.createContext<IdentityContextType>(emptyContext);

const authQueryDocument = graphql(`
  query auth {
    viewer {
      ...IdentityViewer
    }
  }
`);

const logoutDocument = graphql(`
  mutation Logout {
    logout {
      viewer {
        ...IdentityViewer
      }
    }
  }
`);

export function IdentityProvider({ children }: { children: React.ReactNode }) {
  const { data, loading } = useQuery(authQueryDocument, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const [currentOrgId, setOrgId] = React.useState("");
  const [logout] = useMutation(logoutDocument);
  const viewer = useFragment(IdentityViewerFrag, data?.viewer);
  React.useEffect(() => {
    const orgs = viewer?.user?.organizations;
    if (orgs?.length) {
      setOrgId((current) => {
        if (current === "") {
          return orgs[0].id;
        }
        return current;
      });
    }
  }, [viewer, setOrgId]);
  const contextValue: IdentityContextType = React.useMemo(() => {
    if (!data || !viewer) return emptyContext;
    return {
      ...data,
      viewer,
      loading,
      setOrganizationId: setOrgId,
      organizationId: currentOrgId,
      logout,
    };
  }, [data, setOrgId, currentOrgId, loading, viewer, logout]);
  return (
    <IdentityContext.Provider value={contextValue}>
      {children}
    </IdentityContext.Provider>
  );
}

export function useIdentityContext() {
  return React.useContext<IdentityContextType>(IdentityContext);
}
