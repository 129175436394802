import { debounce, DebounceSettings } from "lodash-es";
import React from "react";

export function useDebouncedValue<T>(
  value: T,
  wait: number,
  options: DebounceSettings
) {
  const [current, setCurrent] = React.useState<T>(value);
  const debouncedSetCurrent = React.useMemo(() => {
    return debounce(setCurrent, wait, options);
  }, [setCurrent, wait, options]);
  React.useEffect(() => {
    debouncedSetCurrent(value);
  }, [debouncedSetCurrent, value]);
  return current;
}
