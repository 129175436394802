import React from "react";
import cx from "classnames";
import { FragmentType, graphql, useFragment } from "~src/gql";
import { RouteTitle } from "~src/ui/RouteTitle";
import { SECONDARY_TEXT_COLOR_DIM, SECOND_LAYER } from "~src/ui/classNames";
import { Button } from "~src/ui/button";
import { pluralize } from "~src/lib/string";
import { humanFileSize } from "~src/lib/humanizeFileSize";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const IMAPMigrationTaskConfigFinalizeFrag = graphql(`
  fragment IMAPMigrationTaskConfigFinalize on IMAPMigrationTaskConfig {
    id
    createdAt
    transferDown
    transferUp
    isDraft
    source {
      id
      host
      port
      username
      connectionType
      connectivity {
        server
        login
      }
      stats {
        folders {
          name
          numMessages
        }
        quota
        used
      }
    }
    destination {
      id
      host
      port
      username
      connectionType
      connectivity {
        server
        login
      }
      stats {
        folders {
          name
          numMessages
        }
        quota
        used
      }
    }
  }
`);

const EditIMAPMigrationConfigToFinalizeDocument = graphql(`
  mutation EditIMAPMigrationConfigToFinalize(
    $taskId: String!
    $isDraft: Boolean
  ) {
    editImapMigrationConfig(taskConfigId: $taskId, isDraft: $isDraft) {
      error
      taskConfig {
        __typename
        ... on Node {
          id
        }
      }
    }
  }
`);

export function IMAPMigrationTaskConfigFinalize({
  configKey,
}: {
  configKey: FragmentType<typeof IMAPMigrationTaskConfigFinalizeFrag>;
}) {
  const config = useFragment(IMAPMigrationTaskConfigFinalizeFrag, configKey);
  const navigate = useNavigate();
  const sourceTotalMails = React.useMemo(() => {
    return config.source.stats.folders.reduce((acc, item) => {
      return acc + item.numMessages;
    }, 0);
  }, [config]);
  const destTotalMails = React.useMemo(() => {
    return config.destination.stats.folders.reduce((acc, item) => {
      return acc + item.numMessages;
    }, 0);
  }, [config]);
  const [finalize] = useMutation(EditIMAPMigrationConfigToFinalizeDocument);
  const onConfirm = React.useCallback(() => {
    finalize({ variables: { taskId: config.id, isDraft: false } })
      .then((res) => {
        const error = res.data?.editImapMigrationConfig.error;
        if (error) {
          toast.error(`Unexpected error: ${error}`);
        } else if (res.data?.editImapMigrationConfig.taskConfig?.id) {
          toast.success("Migration task queued.");
          navigate("/migrations");
        }
      })
      .catch(() => {
        toast.error("Unexpected error. Please try again or contact support.");
      });
  }, [finalize, config, navigate]);
  const sourceStats = config.source.stats;
  const destStats = config.destination.stats;
  return (
    <div className="m-auto w-full max-w-5xl">
      <RouteTitle titleText="IMAP Migration Summary" />
      <div className="mt-6 w-full md:flex gap-4">
        <div className={cx(SECOND_LAYER, "rounded shadow p-4 grow")}>
          <div className={cx(SECONDARY_TEXT_COLOR_DIM, "text-sm")}>From</div>
          <div>{config.source.username}</div>
          <div className={cx(SECONDARY_TEXT_COLOR_DIM, "text-sm mt-4")}>
            IMAP server
          </div>
          <div>{config.source.host}</div>
          <div className={cx(SECONDARY_TEXT_COLOR_DIM, "text-sm mt-4")}>
            Number of Messages
          </div>
          <div>
            {sourceTotalMails} {pluralize(sourceTotalMails, "message")}{" "}
            {sourceStats.folders.length}{" "}
            {pluralize(sourceStats.folders.length, "folder")}
          </div>
        </div>
        <div
          className={cx(SECOND_LAYER, "rounded shadow p-4 grow mt-4 md:mt-0")}
        >
          <div className={cx(SECONDARY_TEXT_COLOR_DIM, "text-sm")}>To</div>
          <div>{config.destination.username}</div>
          <div className={cx(SECONDARY_TEXT_COLOR_DIM, "text-sm mt-4")}>
            IMAP server
          </div>
          <div>{config.destination.host}</div>
          <div className={cx(SECONDARY_TEXT_COLOR_DIM, "text-sm mt-4")}>
            Existing messages
          </div>
          <div>
            {destTotalMails === 0 ? (
              "No existing messages"
            ) : (
              <>
                {destTotalMails} {pluralize(destTotalMails, "message")}{" "}
                {destStats.folders.length}{" "}
                {pluralize(destStats.folders.length, "folder")}
              </>
            )}
          </div>
          <div className={cx(SECONDARY_TEXT_COLOR_DIM, "text-sm mt-4")}>
            Quota
          </div>
          <div>
            {destStats.quota === 0
              ? "No quota information available"
              : humanFileSize(destStats.quota - destStats.used) + " available"}
          </div>
        </div>
      </div>
      <div className="mt-4">
        <Button className="block w-full" onClick={onConfirm}>
          Start migration
        </Button>
      </div>
    </div>
  );
}
