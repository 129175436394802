import React from "react";
import cx from "classnames";
import { MenuSections } from "./menu";
import { MenuWithTrigger } from "./menuWithTrigger";
import { Button } from "./button";
import { HiOutlineEllipsisVertical as KebabIcon } from "react-icons/hi2";
import { BASE_LAYER_HOVER, TEXT_COLOR } from "./classNames";

type Props = {
  titleText: string;
  actions?: React.ReactNode;
  moreActions?: MenuSections;
};
export function RouteTitle({ titleText, moreActions, actions }: Props) {
  return (
    <div className="flex justify-between items-center">
      <h1 className={cx("text-3xl text-bold font-poppins")}>{titleText}</h1>
      <div className="flex items-center shrink-0 gap-2">
        {actions}
        {moreActions && (
          <MenuWithTrigger
            trigger={
              <Button
                variant="transparent"
                className={cx(
                  TEXT_COLOR,
                  BASE_LAYER_HOVER,
                  "rounded w-min sm:mt-0",
                  "flex items-center gap-x-2 py-2 sm:p-0"
                )}
                noPadding
              >
                {" "}
                <KebabIcon size={36} />
              </Button>
            }
            menuSections={moreActions}
          />
        )}
      </div>
    </div>
  );
}
