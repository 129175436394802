import {
  ApolloClient,
  defaultDataIdFromObject,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";

import { createClient } from "graphql-ws";

const httpLink = new HttpLink({
  uri: (op) => {
    return `/graphql?op=${op.operationName}`;
  },
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: () => {
      const url = new URL(window.location.href);
      let scheme = "ws:";
      if (url.protocol === "https:") {
        scheme = "wss:";
      }
      return `${scheme}//${url.host}/graphql`;
    },
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export const apolloClient = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    dataIdFromObject: (obj) => {
      if ("id" in obj && obj.id) return obj.id as string;
      return defaultDataIdFromObject(obj);
    },
  }),
});

if (process.env.NODE_ENV === "development") {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.apollo = apolloClient;
}
