import { debounce } from "lodash-es";
import React from "react";
import { useForm } from "react-hook-form";
import { ImapConnType } from "~src/gql/graphql";
import {
  getIMAPAutoConfig,
  getIMAPConnectivity,
  IMAPConnectivityResult,
} from "~src/lib/imap";
import { Input } from "~src/ui/input";

import { HiOutlineCheck as CheckIcon } from "react-icons/hi2";

export type IMAPMailboxFormValue = {
  username: string;
  password: string;
  host: string;
  port?: number;
  connectionType?: ImapConnType;
};

type IMAPMailboxFormProps = {
  onChange?: (newData: IMAPMailboxFormValue) => void;
  defaultValue?: Partial<IMAPMailboxFormValue>;
  onConnectivityChange?: (connectivity: IMAPConnectivityResult) => void;
};

export function IMAPMailboxForm(props: IMAPMailboxFormProps) {
  const { onChange, defaultValue, onConnectivityChange } = props;
  const usernameId = React.useId();
  const passwordId = React.useId();
  const hostId = React.useId();
  const portId = React.useId();
  const initialValue: IMAPMailboxFormValue = React.useMemo(() => {
    return {
      username: "",
      password: "",
      host: "",
      ...defaultValue,
    };
  }, [defaultValue]);
  const [serverConnectivity, setServerConnectivity] = React.useState(false);
  const { register, watch, setValue, getFieldState } =
    useForm<IMAPMailboxFormValue>({
      defaultValues: initialValue,
    });
  React.useEffect(() => {
    async function onValueChange(values: Partial<IMAPMailboxFormValue>) {
      const { host, port } = values;
      if (host && /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/.exec(host)) {
        const connectivity = await getIMAPConnectivity({ ...values, host });
        onConnectivityChange?.({ ...connectivity });
        if (connectivity.port && !port && !getFieldState("port").isTouched) {
          setValue("port", connectivity.port, { shouldTouch: true });
        }
        if (connectivity.server) {
          setServerConnectivity(true);
        }
      }
    }
    const debouncedOnValueChange = debounce(onValueChange, 800, {
      trailing: true,
    });
    return watch((values) => {
      debouncedOnValueChange(values);
    }).unsubscribe;
  }, [
    watch,
    onConnectivityChange,
    getFieldState,
    setValue,
    setServerConnectivity,
  ]);
  React.useEffect(() => {
    return watch((values) => onChange?.({ ...initialValue, ...values }))
      .unsubscribe;
  }, [watch, onChange, initialValue]);
  React.useEffect(() => {
    onChange?.(initialValue);
  }, [onChange, initialValue]);
  React.useEffect(() => {
    return watch((values) => {
      const domain = values.username?.split("@", 2).at(1) || "";
      if (/^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/.exec(domain)) {
        getIMAPAutoConfig(domain).then(({ port, host }) => {
          const { host: currentHost, port: currentPort } = values;
          if (!currentHost && !currentPort) {
            if (host !== currentHost) {
              setValue("host", host);
            }
            if (port && port !== currentPort) {
              setValue("port", port);
            }
          }
        });
      }
    }).unsubscribe;
  }, [watch, setValue]);
  return (
    <div>
      <div>
        <label htmlFor={usernameId} className="sr-only dark:text-white">
          Username
        </label>
        <Input
          id={usernameId}
          type="text"
          required
          className="relative block w-full mb-3 py-3"
          placeholder="Username"
          autoComplete="off"
          {...register("username")}
        />
      </div>
      <div>
        <label htmlFor={passwordId} className="sr-only dark:text-white">
          Password
        </label>
        <Input
          id={passwordId}
          type="password"
          required
          className="relative block w-full mb-3 py-3"
          placeholder="Password"
          autoComplete="off"
          {...register("password")}
        />
      </div>
      <div className="relative">
        <label htmlFor={hostId} className="sr-only dark:text-white">
          Host
        </label>
        <Input
          id={hostId}
          type="text"
          required
          className="relative block w-full mb-3 py-3 pr-8"
          placeholder="IMAP host"
          autoComplete="off"
          {...register("host")}
        />
        {serverConnectivity && (
          <div
            aria-hidden
            className="absolute top-0 right-2 flex items-center pl-3 h-full z-10 text-green-600 dark:text-green-500"
          >
            <CheckIcon size={20} />
          </div>
        )}
      </div>
      <div className="relative">
        <label htmlFor={portId} className="sr-only dark:text-white">
          Port
        </label>
        <Input
          id={portId}
          type="text"
          required
          className="relative block w-full mb-3 py-3 pr-8"
          placeholder="IMAP port (usually 933 or 143)"
          autoComplete="off"
          {...register("port", { valueAsNumber: true, min: 1, max: 65535 })}
        />
        {serverConnectivity && (
          <div
            aria-hidden
            className="absolute top-0 right-2 flex items-center pl-3 h-full z-10 text-green-600 dark:text-green-500"
          >
            <CheckIcon size={20} />
          </div>
        )}
      </div>
    </div>
  );
}
