import React, { Suspense } from "react";
import cx from "classnames";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./lib/apollo";
import { IdentityProvider, useIdentityContext } from "./identity/context";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Auth } from "./identity/auth";
import { DarkModeContext, DarkModeProvider } from "./lib/darkMode";
import { GlobalNav } from "./globalNav/globalNav";
import { BASE_LAYER, TEXT_COLOR } from "./ui/classNames";

import logoURL from "~/assets/LogoText.png";
import {
  GlobalNavContext,
  GlobalNavProvider,
  GlobalNavToggleButton,
} from "./globalNav/context";
import MigrationsPage from "./migrations";
import { IMAPMigrationTaskConfigNew } from "./migrations/IMAPMigrationTaskConfigNew";
import { FinalizeConfig } from "./common/finalizeConfig";
import { BlockLoader, Loader } from "./ui/loader";
import { useViewerMessages } from "./lib/viewerMessagesSubscription";
import { ForgotPassword } from "./identity/forgot";
import { ResetPassword } from "./identity/reset";
import { PromptContainer } from "./ui/prompt";
import { Footer } from "./common/Footer";
import { AuthLayout } from "./identity/layout";
const BackupsPage = React.lazy(() => import("./backups"));

const mainRouter = createBrowserRouter([
  {
    path: "/",
    element: <RootRoute />,
    errorElement: <div>Error</div>,
    children: [
      {
        path: "/migrations",
        element: <MigrationsPage />,
      },
      {
        path: "/migrations/new/imap",
        element: <IMAPMigrationTaskConfigNew />,
      },
      {
        path: "/migrations/finalize/:id",
        element: <FinalizeConfig />,
      },
      {
        path: "/backups",
        element: (
          <Suspense fallback={<BlockLoader />}>
            <BackupsPage />
          </Suspense>
        ),
      },
      {
        path: "*",
        element: <div>Not found</div>,
      },
    ],
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        path: "/auth",
        element: <Auth />,
      },
      {
        path: "/auth/reset",
        element: <ResetPassword />,
      },
      {
        path: "/auth/forgot",
        element: <ForgotPassword />,
      },
    ],
  },
]);

function RootRoute() {
  const { toggleIsCollapsed, isCollapsed } = React.useContext(GlobalNavContext);
  const { viewer, organizationId, loading } = useIdentityContext();
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    if (!viewer.user && !loading) {
      navigate("/auth");
    } else if (location.pathname === "/") {
      navigate("/migrations");
    }
  }, [viewer, navigate, loading, location]);
  useViewerMessages();
  if (!viewer.user) {
    return (
      <div
        className={cx(
          "h-full w-full flex justify-center items-center",
          TEXT_COLOR
        )}
      >
        <Loader size={36} />
      </div>
    );
  }
  return (
    <div className="flex">
      <GlobalNav />
      <div
        onClick={toggleIsCollapsed}
        className={cx(
          "global-nav-backdrop sm:hidden opacity-10",
          "fixed top-0 bottom-0 left-0 right-0 z-10",
          "bg-neutral-500 dark:bg-neutral-500",
          {
            hidden: isCollapsed,
          }
        )}
      ></div>
      <div
        className={cx(
          "ml-0 flex flex-col w-full h-screen overflow-auto overscroll-none px-4"
        )}
      >
        <div
          className={cx(
            "topbar flex items-center sticky top-0 w-full max-w-5xl m-auto sm:hidden",
            BASE_LAYER
          )}
          style={{
            height: "var(--topbar-height)",
            minHeight: "var(--topbar-height)",
          }}
        >
          <div className="sm:hidden">
            <GlobalNavToggleButton />
          </div>
          <img
            style={{ transform: "translate3d(-4px, 0, 0)" }}
            className="h-12 w-auto sm:block hidden dark:brightness-80"
            src={logoURL}
            alt={`Logo of ${window.SITE_NAME}`}
          />
        </div>
        <div
          className={cx(
            BASE_LAYER,
            TEXT_COLOR,
            "grow sm:rounded-tl-lg mt-4 flex flex-col"
          )}
        >
          <div className="grow">
            {organizationId ? <Outlet /> : <BlockLoader />}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

function AutoDarkToastContainer() {
  const darkContext = React.useContext(DarkModeContext);
  return (
    <ToastContainer
      position="bottom-center"
      theme={darkContext.isDarkMode ? "dark" : "light"}
    />
  );
}

export function Root() {
  return (
    <React.StrictMode>
      <ApolloProvider client={apolloClient}>
        <IdentityProvider>
          <DarkModeProvider>
            <GlobalNavProvider>
              <RouterProvider router={mainRouter} />
              <AutoDarkToastContainer />
              <PromptContainer />
            </GlobalNavProvider>
          </DarkModeProvider>
        </IdentityProvider>
      </ApolloProvider>
    </React.StrictMode>
  );
}
