import cx from "classnames";
import React from "react";
import { Button } from "~src/ui/button";

type Props = {
  hasPrev?: boolean;
  hasNext?: boolean;
  onPrev?: () => void;
  onNext?: () => void;
  className?: string;
};
export function PrevNextPaginator({
  hasNext,
  hasPrev,
  onNext,
  onPrev,
  className,
}: Props) {
  return (
    <div
      className={cx(className, "paginator", "flex w-100 justify-center gap-2")}
    >
      {hasPrev && (
        <Button variant="secondary" onClick={onPrev}>
          Prev
        </Button>
      )}
      {hasNext && (
        <Button variant="secondary" onClick={onNext}>
          Next
        </Button>
      )}
    </div>
  );
}
