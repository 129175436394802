import React from "react";
import {
  IMAPMailboxForm,
  IMAPMailboxFormValue,
} from "~src/mailbox/IMAPMailboxForm";
import { Button } from "~src/ui/button";
import { RouteTitle } from "~src/ui/RouteTitle";
import { HiOutlineArrowRight as NextIcon } from "react-icons/hi2";
import { graphql } from "~src/gql";
import { useMutation } from "@apollo/client";
import { useIdentityContext } from "~src/identity/context";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CreateIMAPMigrationTaskConfigMutationDocument = graphql(`
  mutation createIMAPMigrationTaskConfig(
    $organizationId: String!
    $srcHost: String!
    $srcPort: Int
    $srcConnectionType: IMAPConnType
    $srcUsername: String!
    $srcPassword: String!
    $destHost: String!
    $destPort: Int
    $destConnectionType: IMAPConnType
    $destUsername: String!
    $destPassword: String!
    $repeatUnit: RepeatUnit
    $repeatCountTotal: Int
    $isDraft: Boolean
  ) {
    createImapMigrationConfig(
      organizationId: $organizationId
      srcHost: $srcHost
      srcPort: $srcPort
      srcConnectionType: $srcConnectionType
      srcUsername: $srcUsername
      srcPassword: $srcPassword
      destHost: $destHost
      destPort: $destPort
      destConnectionType: $destConnectionType
      destUsername: $destUsername
      destPassword: $destPassword
      repeatUnit: $repeatUnit
      repeatCountTotal: $repeatCountTotal
      isDraft: $isDraft
    ) {
      error
      taskConfig {
        ... on Node {
          id
        }
        ... on IMAPMigrationTaskConfig {
          id
        }
      }
    }
  }
`);

export function IMAPMigrationTaskConfigNew() {
  const { organizationId } = useIdentityContext();
  const navigate = useNavigate();
  const [sourceMailboxValues, setSourceMailboxValues] =
    React.useState<IMAPMailboxFormValue>({
      username: "",
      password: "",
      host: "",
    });
  const [destMailboxValues, setDestMailboxValues] =
    React.useState<IMAPMailboxFormValue>({
      username: "",
      password: "",
      host: "",
    });
  const [createIMAPMigrationConfig] = useMutation(
    CreateIMAPMigrationTaskConfigMutationDocument
  );
  const onSubmit = React.useCallback(() => {
    createIMAPMigrationConfig({
      variables: {
        srcHost: sourceMailboxValues.host,
        srcUsername: sourceMailboxValues.username,
        srcPassword: sourceMailboxValues.password,
        srcPort: sourceMailboxValues.port,
        destHost: destMailboxValues.host,
        destUsername: destMailboxValues.username,
        destPassword: destMailboxValues.password,
        destPort: destMailboxValues.port,
        isDraft: true,
        organizationId,
      },
    })
      .then((res) => {
        const id = res.data?.createImapMigrationConfig.taskConfig?.id;
        if (id) {
          navigate(`/migrations/finalize/${id}`);
        } else {
          throw new Error("Failed");
        }
      })
      .catch(() => {
        toast.error(
          "Oops, some error occurred, please try again or contact support."
        );
      });
  }, [
    sourceMailboxValues,
    destMailboxValues,
    createIMAPMigrationConfig,
    navigate,
    organizationId,
  ]);
  return (
    <div className="m-auto w-full max-w-5xl">
      <RouteTitle titleText="New IMAP Mailbox Migration" />
      <div className="mt-6 gap-4 gap-y-6 grid grid-cols-1 lg:grid-cols-2">
        <div>
          <h2 className="mb-4 text-2xl">Source mailbox</h2>
          <IMAPMailboxForm onChange={setSourceMailboxValues} />
        </div>
        <div>
          <h2 className="mb-4 text-2xl">Destination mailbox</h2>
          <IMAPMailboxForm onChange={setDestMailboxValues} />
        </div>
      </div>
      <div className="mt-12 m-auto">
        <Button className="block w-full relative group" onClick={onSubmit}>
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            <NextIcon
              size={20}
              className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
              aria-hidden="true"
            />
          </span>
          Next
        </Button>
      </div>
    </div>
  );
}
