import React from "react";
import cx from "classnames";
import { HiLockClosed as LockClosedIcon } from "react-icons/hi2";

import { Input } from "~src/ui/input";
import { Button } from "~src/ui/button";
import { HEADING_1, LINK_COLOR, TEXT_COLOR } from "~src/ui/classNames";
import { useForm } from "react-hook-form";
import { graphql } from "~src/gql";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

type ResetPasswordFormData = {
  newPassword: string;
};

const resetPasswordMutationDocument = graphql(`
  mutation resetPassword($newPassword: String!, $resetToken: String!) {
    resetPassword(newPassword: $newPassword, resetToken: $resetToken) {
      ok
    }
  }
`);

export function ResetPassword() {
  const navigate = useNavigate();
  const { register, getValues } = useForm<ResetPasswordFormData>();
  const [resetPassword] = useMutation(resetPasswordMutationDocument);
  const onSubmit = React.useCallback(() => {
    const { newPassword } = getValues();
    const token = new URLSearchParams(window.location.search).get("token");
    if (token === null) {
      toast.error("Rest token is not valid. Please try again.");
      return;
    }
    resetPassword({ variables: { newPassword, resetToken: token } })
      .then((res) => {
        if (res.data?.resetPassword.ok) {
          toast.success("Password changed successfully.");
          navigate("/auth");
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        toast.error("Something went wrong. Reset token seems to be invalid.");
      });
  }, [getValues, resetPassword, navigate]);
  return (
    <>
      <div>
        <h2 className={cx("mt-6 text-center", HEADING_1)}>Reset password</h2>
        <p className={cx("mt-2 text-center text-sm ", TEXT_COLOR)}>
          Or{" "}
          <Link to="/auth" className={cx("font-medium", LINK_COLOR)}>
            sign in
          </Link>
        </p>
      </div>
      <form className="mt-8 space-y-6">
        <div className="-space-y-px rounded-md shadow-sm">
          <div>
            <label
              htmlFor="user-identifier"
              className="sr-only dark:text-white"
            >
              New password
            </label>
            <Input
              id="user-identifier"
              type="text"
              required
              className="relative block w-full mb-2"
              placeholder="New password"
              {...register("newPassword")}
            />
          </div>
        </div>

        <div>
          <Button
            type="button"
            className="relative flex w-full"
            onClick={onSubmit}
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <LockClosedIcon
                size={20}
                className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                aria-hidden="true"
              />
            </span>
            Reset password
          </Button>
        </div>
      </form>
    </>
  );
}
