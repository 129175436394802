import { Cache } from "./cache";
import { apolloClient } from "./apollo";
import { graphql } from "~src/gql";
import { ImapConnType } from "~src/gql/graphql";

export type IMAPConnectivityResult = {
  server: boolean;
  login: boolean;
  port?: number | null;
};

const _autoConfigCache = new Cache<{ host: string; port: number }>(true);
const _connectivityCache = new Cache<IMAPConnectivityResult>(true);

const IMAPAutoConfigQueryDocument = graphql(`
  query IMAPAutoConfig($domain: String!) {
    viewer {
      id
      IMAPAutoconfig(domain: $domain) {
        port
        host
      }
    }
  }
`);

const IMAPConnectivityQueryDocument = graphql(`
  query IMAPConnectivity(
    $host: String!
    $port: Int
    $connType: IMAPConnType! = AUTO
    $username: String! = ""
    $password: String! = ""
  ) {
    viewer {
      id
      IMAPConnectivity(
        host: $host
        port: $port
        connType: $connType
        username: $username
        password: $password
      ) {
        server
        login
        port
      }
    }
  }
`);

async function _getIMAPAutoConfig(
  domain: string
): Promise<{ host: string; port: number }> {
  try {
    const res = await apolloClient.query({
      query: IMAPAutoConfigQueryDocument,
      variables: { domain },
    });
    return res.data.viewer.IMAPAutoconfig;
  } catch {
    return { host: "", port: 0 };
  }
}

export async function getIMAPAutoConfig(
  domain: string
): Promise<{ host: string; port: number }> {
  const existing = _autoConfigCache.get(domain);
  if (existing !== undefined) {
    return existing;
  }
  const res = await _getIMAPAutoConfig(domain);
  _autoConfigCache.put(domain, res);
  return res;
}

function makeIMAPConnectivigyCacheKey({
  username = "",
  password = "",
  host,
  port,
}: {
  username?: string;
  password?: string;
  host: string;
  port?: number;
}) {
  if (username && password) {
    return [username, password, host, port].join("|");
  }
  return ["", "", host, port].join("|");
}

export async function getIMAPConnectivity(creds: {
  username?: string;
  password?: string;
  host: string;
  port?: number;
  connType?: ImapConnType;
}): Promise<IMAPConnectivityResult> {
  const { username = "", password = "", host, connType = "AUTO" } = creds;
  let { port } = creds;
  if (typeof port === "string") {
    port = parseInt(port, 10);
  }
  const cacheKey = makeIMAPConnectivigyCacheKey(creds);
  const existing = _connectivityCache.get(cacheKey);
  if (existing !== undefined) return existing;
  try {
    const res = await apolloClient.query({
      query: IMAPConnectivityQueryDocument,
      variables: {
        host,
        port,
        username,
        password,
        connType,
      },
    });
    const result = res.data.viewer.IMAPConnectivity;
    _connectivityCache.put(cacheKey, result);
    if (result.port) {
      const preCacheKey = makeIMAPConnectivigyCacheKey({
        ...creds,
        port: result.port,
      });
      _connectivityCache.put(preCacheKey, result);
    }
    return result;
  } catch {
    return { login: false, server: false };
  }
}
