import React from "react";
import cx from "classnames";
import { HiLockClosed as LockClosedIcon } from "react-icons/hi2";

import { Input } from "~src/ui/input";
import { Button } from "~src/ui/button";
import { HEADING_1, LINK_COLOR, TEXT_COLOR } from "~src/ui/classNames";
import { useForm } from "react-hook-form";
import { graphql } from "~src/gql";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

type AuthFormData = {
  username: string;
  password: string;
  password2: string;
};

const createUserMutationDocument = graphql(`
  mutation createUser(
    $username: String!
    $password: String!
    $password2: String!
  ) {
    createUser(
      username: $username
      password: $password
      password2: $password2
    ) {
      ok
      viewer {
        ...IdentityViewer
        user {
          id
        }
      }
    }
  }
`);

const authenticateMutationDocument = graphql(`
  mutation authenticate($username: String!, $password: String!) {
    authenticate(username: $username, password: $password) {
      ok
      viewer {
        ...IdentityViewer
      }
    }
  }
`);

export function Auth() {
  const [mode, setMode] = React.useState<"login" | "reg">("login");
  const apolloClient = useApolloClient();
  const { register, getValues, reset } = useForm<AuthFormData>();
  const navigate = useNavigate();
  const setLogin = React.useCallback(() => {
    setMode("login");
    reset();
  }, [setMode, reset]);
  const setReg = React.useCallback(() => {
    setMode("reg");
    reset();
  }, [setMode, reset]);
  const [authenticate] = useMutation(authenticateMutationDocument);
  const [createUser] = useMutation(createUserMutationDocument);
  const onLogin = React.useCallback(() => {
    const { username, password } = getValues();
    authenticate({ variables: { username, password } }).then((res) => {
      if (res.data?.authenticate.ok) {
        toast.success("Welcome back!");
        apolloClient.refetchQueries({ include: ["auth"] }).then(() => {
          navigate("/");
        });
      } else {
        toast.error("Authenticate failed. Please check your credentials.");
      }
    });
  }, [getValues, authenticate, apolloClient, navigate]);
  const onCreateUser = React.useCallback(() => {
    const { username, password, password2 } = getValues();
    createUser({ variables: { username, password, password2 } }).then((res) => {
      if (res.data?.createUser.ok && res.data.createUser.viewer.user) {
        toast.success(`Thanks for choosing ${window.SITE_NAME}!`);
        apolloClient.refetchQueries({ include: ["auth"] }).then(() => {
          navigate("/");
        });
      } else {
        toast.error(
          "User creation failed. Please try again or contact support."
        );
      }
    });
  }, [getValues, createUser, apolloClient, navigate]);
  const onSubmit = React.useCallback(() => {
    if (mode === "login") {
      onLogin();
    } else if (mode === "reg") {
      onCreateUser();
    }
  }, [mode, onLogin, onCreateUser]);
  return (
    <>
      <div>
        <h2 className={cx("mt-6 text-center", HEADING_1)}>
          {mode === "login"
            ? "Sign in to your account"
            : "Create a new account"}
        </h2>
        {mode === "login" ? (
          <p className={cx("mt-2 text-center text-sm ", TEXT_COLOR)}>
            Or{" "}
            <a
              href="#"
              onClick={setReg}
              className={cx("font-medium", LINK_COLOR)}
            >
              create an account
            </a>
          </p>
        ) : (
          <p className={cx("mt-2 text-center text-sm ", TEXT_COLOR)}>
            Or{" "}
            <a
              href="#"
              onClick={setLogin}
              className={cx("font-medium", LINK_COLOR)}
            >
              sign in
            </a>
          </p>
        )}
      </div>
      <form className="mt-8 space-y-6">
        <div className="-space-y-px rounded-md shadow-sm">
          <div>
            <label
              htmlFor="user-identifier"
              className="sr-only dark:text-white"
            >
              Email
            </label>
            <Input
              id="user-identifier"
              type="email"
              required
              className="relative block w-full mb-2"
              placeholder="Email"
              {...register("username")}
            />
          </div>
          <div>
            <label htmlFor="password" className="sr-only dark:text-white">
              Password
            </label>
            <Input
              id="password"
              type="password"
              required
              className="relative block w-full"
              placeholder="Password"
              {...register("password")}
            />
          </div>
          {mode === "reg" && (
            <div>
              <label htmlFor="password2" className="sr-only dark:text-white">
                Repeat Password
              </label>
              <Input
                id="password2"
                type="password"
                required
                className="relative block w-full mt-2"
                placeholder="Repeat Password"
                {...register("password2")}
              />
            </div>
          )}
        </div>

        <div className="flex items-center justify-end">
          <div className="text-sm">
            <Link to="/auth/forgot" className={cx("font-medium", LINK_COLOR)}>
              Forgot your password?
            </Link>
          </div>
        </div>

        <div>
          <Button
            type="button"
            className="relative flex w-full"
            onClick={onSubmit}
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <LockClosedIcon
                size={20}
                className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                aria-hidden="true"
              />
            </span>
            {mode == "login" ? "Sign in" : "Create account"}
          </Button>
        </div>
      </form>
    </>
  );
}
