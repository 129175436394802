import React from "react";
import { RouteTitle } from "~/src/ui/RouteTitle";
import { graphql } from "~/src/gql";
import { useQuery } from "@apollo/client";
import { BlockLoader } from "~src/ui/loader";
import { IMAPMigrationTaskConfigCard } from "./IMAPMigrationTaskConfigCard";
import { Button } from "~src/ui/button";
import { MenuWithTrigger } from "~src/ui/menuWithTrigger";
import { useNavigate } from "react-router-dom";
import { useIdentityContext } from "~src/identity/context";
import { PrevNextPaginator } from "~src/common/prevNextPagionator";
import { Input } from "~src/ui/input";
import classNames from "classnames";
import { useDebouncedValue } from "~src/lib/useDebouncedValue";
import { noop } from "lodash-es";

const MigrationsConfigsQueryDocument = graphql(`
  query migrationConfigs(
    $organizationId: String!
    $first: Int
    $after: String
    $before: String
    $search: String
    $showArchived: Boolean
  ) {
    viewer {
      id
      taskConfigs(
        first: $first
        after: $after
        before: $before
        organizationId: $organizationId
        search: $search
        showArchived: $showArchived
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            __typename
            ... on IDObjectNode {
              archived
            }
            ... on IMAPMigrationTaskConfig {
              id
              ...IMAPMigrationTaskConfigCardMigrationConfig
            }
          }
        }
      }
    }
  }
`);

const _DEBOUNCE_SEARCH_TERM_OPTIONS = {
  trailing: true,
  leading: false,
};

function MigrationsList({ showArchived = false }: { showArchived?: boolean }) {
  const { organizationId } = useIdentityContext();
  const [searchTerm, setSearchTerm] = React.useState("");
  const onSearchTermChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
    },
    [setSearchTerm]
  );
  const debouncedSearchTerm = useDebouncedValue(
    searchTerm,
    500,
    _DEBOUNCE_SEARCH_TERM_OPTIONS
  );
  const {
    data,
    loading: networkLoading,
    refetch,
    variables,
  } = useQuery(MigrationsConfigsQueryDocument, {
    notifyOnNetworkStatusChange: true,
    variables: { organizationId, first: 6, showArchived },
    fetchPolicy: "cache-and-network",
  });
  const pageInfo = data?.viewer.taskConfigs.pageInfo;
  const onNext = React.useCallback(() => {
    refetch({ after: pageInfo?.endCursor, before: null });
  }, [refetch, pageInfo]);
  const onPrev = React.useCallback(() => {
    refetch({ before: pageInfo?.startCursor, after: null });
  }, [refetch, pageInfo]);
  React.useEffect(() => {
    refetch({ search: debouncedSearchTerm, before: null, after: null });
  }, [refetch, debouncedSearchTerm]);
  return (
    <>
      <Input
        value={searchTerm}
        onChange={onSearchTermChange}
        placeholder="Search"
        className={classNames("w-full mb-4")}
      />
      {!data ||
      networkLoading ||
      searchTerm !== debouncedSearchTerm ||
      (variables?.search || "") !== debouncedSearchTerm ? (
        <BlockLoader />
      ) : (
        <>
          <div className="flex flex-col gap-y-4">
            {data?.viewer.taskConfigs.edges.map(({ node }) => {
              if (node.__typename === "IMAPMigrationTaskConfig") {
                if (!node) return null; // to prevent dangling refs
                if (node.archived && !showArchived) return null;
                return (
                  <IMAPMigrationTaskConfigCard config={node} key={node.id} />
                );
              }
            })}
          </div>
          <PrevNextPaginator
            className="mt-4"
            hasNext={pageInfo?.hasNextPage}
            hasPrev={pageInfo?.hasPreviousPage}
            onNext={onNext}
            onPrev={onPrev}
          />
        </>
      )}
    </>
  );
}

export default function MigrationsPage() {
  const [showArchived, setShowArchived] = React.useState(false);
  const onShowArchivedToggle = React.useCallback(() => {
    setShowArchived((current) => !current);
  }, [setShowArchived]);
  const navigate = useNavigate();
  return (
    <div className="m-auto w-full max-w-5xl">
      <RouteTitle
        titleText="Mailbox Migrations"
        actions={
          <>
            <MenuWithTrigger
              trigger={<Button className="w-max">Create new</Button>}
              menuSections={{
                children: [
                  {
                    id: "main",
                    items: [
                      {
                        id: "imap_migration",
                        children: "IMAP Migration",
                        action: ({ close }) => {
                          navigate("/migrations/new/imap");
                          close?.();
                        },
                      },
                    ],
                  },
                ],
              }}
            />
          </>
        }
        moreActions={{
          children: [
            {
              id: "more1",
              items: [
                {
                  id: "showArchived",
                  children: (
                    <div className="flex items-center">
                      <input
                        checked={showArchived}
                        type="checkbox"
                        className="w-4 h-4 mr-1 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        onChange={noop}
                      />
                      Show archived
                    </div>
                  ),
                  action: onShowArchivedToggle,
                },
              ],
            },
          ],
        }}
      />
      <div className="mt-8">
        <MigrationsList showArchived={showArchived} />
      </div>
    </div>
  );
}
