import React from "react";
import cx from "classnames";

const _COMMON_CLASS =
  "p-1 rounded-lg text-sm text-neutral-50 flex justify-center select-none w-min";

export function TaskStatusBadge({
  pending = false,
  percentage = 0,
  error = false,
  finished = false,
  isDraft = false,
}: {
  pending?: boolean;
  percentage?: number;
  error?: boolean;
  finished?: boolean;
  isDraft?: boolean;
}) {
  if (isDraft) {
    return (
      <div className={cx("bg-neutral-500 dark:bg-neutral-600", _COMMON_CLASS)}>
        Draft
      </div>
    );
  }
  if (error) {
    return (
      <div className={cx("bg-red-500 dark:bg-red-600", _COMMON_CLASS)}>
        Error
      </div>
    );
  }
  if (finished) {
    return (
      <div className={cx("bg-green-600 dark:bg-green-600", _COMMON_CLASS)}>
        Done
      </div>
    );
  }
  if (pending) {
    return (
      <div className={cx("bg-neutral-500 dark:bg-neutral-600", _COMMON_CLASS)}>
        Pending
      </div>
    );
  }
  return (
    <div className={cx("bg-indigo-500 dark:bg-indigo-600", _COMMON_CLASS)}>
      {percentage}%
    </div>
  );
}
