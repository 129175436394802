import { useSubscription } from "@apollo/client";
import { graphql } from "~src/gql";

const viewerMessagesSubscriptionDocument = graphql(`
  subscription ViewerMessages {
    viewerMessages {
      __typename
      ... on TextMessage {
        message
      }
      ... on IMAPMigrationTaskProgress {
        changedNode {
          id
          runs {
            id
            startedAt
            finishedAt
            hasError
            progressTotal
            progressCurrent
          }
        }
      }
    }
  }
`);

export function useViewerMessages() {
  useSubscription(viewerMessagesSubscriptionDocument, {
    fetchPolicy: "network-only",
    shouldResubscribe: true,
  });
}
