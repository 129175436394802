import React from "react";
import cx from "classnames";
import { FragmentType, graphql, useFragment } from "~src/gql";
import {
  SECONDARY_TEXT_COLOR_DIM,
  SECOND_LAYER,
  SECOND_LAYER_HOVER,
} from "~src/ui/classNames";
import { HiOutlineArrowRight as RightArrowIcon } from "react-icons/hi2";
import { HiOutlineEllipsisHorizontal as KebabIcon } from "react-icons/hi2";
import { TaskStatusBadge } from "~src/ui/taskStatusBadge";
import { Button } from "~src/ui/button";
import { MenuWithTrigger } from "~src/ui/menuWithTrigger";
import { MenuSections } from "~src/ui/menu";
import { useNavigate } from "react-router-dom";
import { archiveOrDelete } from "~src/common/archiveOrDelete";
import { compact } from "lodash-es";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { prompt } from "~src/ui/prompt";

const IMAPMigrationTaskConfigCardMigratioConfignFrag = graphql(`
  fragment IMAPMigrationTaskConfigCardMigrationConfig on IMAPMigrationTaskConfig {
    id
    createdAt
    transferDown
    transferUp
    isDraft
    archived
    runs {
      id
      startedAt
      finishedAt
      hasError
      progressTotal
      progressCurrent
    }
    source {
      id
      host
      port
      username
      connectionType
    }
    destination {
      id
      host
      port
      username
      connectionType
    }
  }
`);

const CreateIMAPMigrationScheduleDocument = graphql(`
  mutation CreateIMAPMigrationSchedule($id: String!) {
    createImapMigrationTaskSchedule(taskConfigId: $id) {
      error
      taskConfig {
        ... on IMAPMigrationTaskConfig {
          ...IMAPMigrationTaskConfigCardMigrationConfig
        }
      }
    }
  }
`);

export function IMAPMigrationTaskConfigCard({
  config,
}: {
  config: FragmentType<typeof IMAPMigrationTaskConfigCardMigratioConfignFrag>;
}) {
  const configData = useFragment(
    IMAPMigrationTaskConfigCardMigratioConfignFrag,
    config
  );
  const navigate = useNavigate();
  const onArchive = React.useCallback(
    ({ close }: { close?: () => void }) => {
      close?.();
      prompt({
        title: "Archive migration",
        yesDestructive: true,
        content: (
          <p>
            This operation will delete saved credentials and is ireversible.
            <br />
            Do you wish to continue?
          </p>
        ),
        yesText: "Yes",
      }).then((res) => {
        if (res) {
          archiveOrDelete([
            { id: configData.id, archived: !configData.archived },
          ]);
        }
      });
    },
    [configData]
  );
  const onDelete = React.useCallback(() => {
    archiveOrDelete([{ id: configData.id, archived: !configData.archived }]);
  }, [configData]);
  const mostRecentRun = React.useMemo(
    () => configData.runs.at(0),
    [configData]
  );
  const [createIMAPMigrationSchedule] = useMutation(
    CreateIMAPMigrationScheduleDocument
  );
  const onRunNow = React.useCallback(() => {
    createIMAPMigrationSchedule({ variables: { id: configData.id } })
      .then((res) => {
        if (res.data?.createImapMigrationTaskSchedule.taskConfig) {
          toast.success("New migration run created");
        } else {
          throw new Error(
            res.data?.createImapMigrationTaskSchedule.error || ""
          );
        }
      })
      .catch((error) => {
        let message = "Something went wrong. Please try again.";
        if (error instanceof Error && error.message) {
          message = error.message;
        }
        toast.error(message);
      });
  }, [createIMAPMigrationSchedule, configData.id]);
  const menuSections: MenuSections = React.useMemo(() => {
    if (configData.isDraft) {
      return {
        children: [
          {
            id: "main",
            items: [
              {
                id: "finalize",
                children: "Finalize",
                action: ({ close }) => {
                  close?.();
                  navigate(`/migrations/finalize/${configData.id}`);
                },
              },
            ],
          },
          {
            id: "main2",
            items: [
              {
                id: "archive",
                children: "Delete",
                variant: "danger",
                action: onDelete,
              },
            ],
          },
        ],
      };
    }
    return {
      children: [
        {
          id: "main",
          items: compact([
            mostRecentRun?.hasError && !configData.archived
              ? { id: "retry", children: "Retry", action: onRunNow }
              : null,
          ]),
        },
        {
          id: "main2",
          items: compact([
            !configData.archived
              ? {
                  id: "archive",
                  children: configData.archived ? "Unarchive" : "Archive",
                  variant: "danger",
                  action: onArchive,
                }
              : null,
          ]),
        },
      ],
    };
  }, [configData, navigate, onArchive, mostRecentRun, onRunNow, onDelete]);
  let sourceUsername = configData.source.username;
  let destinationUsername = configData.destination.username;
  if (sourceUsername === destinationUsername) {
    sourceUsername += `(${configData.source.host})`;
    destinationUsername += `(${configData.destination.host})`;
  }
  const totalMigrated = configData.runs.reduce(
    (acc, run) => acc + run.progressCurrent,
    0
  );
  return (
    <div
      className={cx("rounded p-6 shadow", {
        ["bg-neutral-200 dark:bg-neutral-800 dark:text-neutral-400"]:
          configData.archived,
        [SECOND_LAYER]: !configData.archived,
      })}
    >
      <div className="flex flex-col gap-4 gap-x-8 sm:flex-row">
        <div className="mg-title inline-flex flex-col grid-cols-[1fr_auto_1fr] gap-2 sm:grid sm:items-center shrink-1 min-w-0 grow">
          <div className=" min-w-0 shrink-1 truncate">
            <p className={cx(SECONDARY_TEXT_COLOR_DIM, "text-sm select-none")}>
              From
            </p>
            {sourceUsername}
          </div>
          <div className={cx("hidden sm:block mx-6", SECONDARY_TEXT_COLOR_DIM)}>
            <RightArrowIcon size={24} />
          </div>
          <div className="min-w-0 shrink-1 truncate">
            <p className={cx(SECONDARY_TEXT_COLOR_DIM, "text-sm select-none")}>
              To
            </p>
            {destinationUsername}
          </div>
        </div>
        <div className="mg-status-actions flex flex-col gap-4 sm:items-center sm:justify-between sm:flex-row grow-0">
          <div>
            <p className={cx(SECONDARY_TEXT_COLOR_DIM, "text-sm select-none")}>
              Status
            </p>
            <TaskStatusBadge
              percentage={
                mostRecentRun
                  ? Math.ceil(
                      (mostRecentRun.progressCurrent /
                        (mostRecentRun.progressTotal || 1)) *
                        100
                    )
                  : 0
              }
              isDraft={configData.isDraft}
              error={mostRecentRun?.hasError}
              pending={!mostRecentRun || !mostRecentRun.startedAt}
              finished={!!mostRecentRun?.finishedAt}
            />
          </div>
          <div>
            <p
              className={cx(
                SECONDARY_TEXT_COLOR_DIM,
                "text-sm truncate select-none"
              )}
            >
              Messages migrated
            </p>
            {totalMigrated}
          </div>
          <div>
            <MenuWithTrigger
              trigger={
                <Button
                  variant="transparent"
                  className={cx(
                    SECONDARY_TEXT_COLOR_DIM,
                    SECOND_LAYER_HOVER,
                    "rounded w-min mt-2 sm:mt-0",
                    "flex items-center gap-x-2 py-2 sm:p-0"
                  )}
                  noPadding
                >
                  {" "}
                  <KebabIcon size={36} />
                </Button>
              }
              menuSections={menuSections}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
