import logoURL from "~/assets/LogoText.png";
import React from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "~src/common/Footer";

export function AuthLayout() {
  return (
    <div>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-24 w-auto"
              src={logoURL}
              alt={`Logo of ${window.SITE_NAME}`}
            />
            <Outlet />
          </div>
        </div>
      </div>
      <Footer center />
    </div>
  );
}
