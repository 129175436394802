import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { graphql } from "~src/gql";
import { IMAPMigrationTaskConfigFinalize } from "~src/migrations/IMAPMigrationTaskConfigFinalize";
import { BlockLoader } from "~src/ui/loader";
import { RouteTitle } from "~src/ui/RouteTitle";

const FinalizeConfigQueryDocument = graphql(`
  query FinalizeConfig($id: String!) {
    viewer {
      id
      node(id: $id) {
        id
        ... on IMAPMigrationTaskConfig {
          __typename
          ...IMAPMigrationTaskConfigFinalize
        }
      }
    }
  }
`);

export function FinalizeConfig() {
  const { id } = useParams();
  const { data, loading, error } = useQuery(FinalizeConfigQueryDocument, {
    variables: { id: id || "" },
    fetchPolicy: "network-only",
  });
  const node = data?.viewer.node;
  React.useEffect(() => {
    if (error) {
      let message = "Unknown error.";
      if (error.message) {
        message = error.message;
      }
      toast.error(
        `Ooops, operation faild. ${message}. Please contact support.`
      );
    }
  }, [error]);
  if (loading || !node) {
    return (
      <div className="m-auto w-full max-w-5xl">
        <RouteTitle titleText="Checking mailbox status..." />
        <BlockLoader />
      </div>
    );
  }
  if (node.__typename === "IMAPMigrationTaskConfig") {
    return <IMAPMigrationTaskConfigFinalize configKey={node} />;
  }
  return <div>Not found</div>;
}
