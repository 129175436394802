export class Cache<T> {
  private values: Map<string, T> = new Map<string, T>();
  private maxEntries = 20;

  constructor(private useLRU: boolean) {}

  public get(key: string): T | undefined {
    const existing = this.values.get(key);
    if (this.useLRU && existing) {
      this.values.delete(key);
      this.values.set(key, existing);
    }
    return existing;
  }

  public has(key: string): boolean {
    return this.values.has(key);
  }

  public put(key: string, value: T) {
    if (this.values.size >= this.maxEntries) {
      const keyToDelete = this.values.keys().next().value;
      this.values.delete(keyToDelete);
    }

    this.values.set(key, value);
  }
}
