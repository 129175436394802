import React from "react";
import "react-toastify/dist/ReactToastify.css";

import "./App.tsx";
import "./entry.css";
import "./lib/darkMode";

import { reactRoot } from "./reactRoot";
import { Root } from "./App";

reactRoot.render(<Root />);
