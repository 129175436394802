import React from "react";
import cx from "classnames";
import { BREAK_POINT_MIN_WIDTH } from "~src/ui/breakpoint";
import { Button } from "~src/ui/button";
import { BASE_LAYER_HOVER, SECONDARY_TEXT_COLOR } from "~src/ui/classNames";
import { HiBars3 as Bars3Icon } from "react-icons/hi2";

type GlobalNavContextType = {
  isCollapsed: boolean;
  toggleIsCollapsed: () => void;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};
const emptyContext: GlobalNavContextType = {
  isCollapsed: false,
  toggleIsCollapsed: () => undefined,
  setIsCollapsed: () => undefined,
};
export const GlobalNavContext =
  React.createContext<GlobalNavContextType>(emptyContext);

export function GlobalNavProvider({ children }: { children: React.ReactNode }) {
  const [isCollapsed, setIsCollapsed] = React.useState(
    window.innerWidth < BREAK_POINT_MIN_WIDTH.lg
  );
  const toggleIsCollapsed = React.useCallback(() => {
    setIsCollapsed((current) => {
      return !current;
    });
  }, [setIsCollapsed]);
  const contextValue: GlobalNavContextType = {
    isCollapsed,
    toggleIsCollapsed,
    setIsCollapsed,
  };
  return (
    <GlobalNavContext.Provider value={contextValue}>
      {children}
    </GlobalNavContext.Provider>
  );
}

export function GlobalNavToggleButton() {
  const { toggleIsCollapsed } = React.useContext(GlobalNavContext);
  return (
    <Button
      onClick={toggleIsCollapsed}
      variant="transparent"
      className={cx(BASE_LAYER_HOVER)}
      roundLevel="full"
    >
      <Bars3Icon className={cx(SECONDARY_TEXT_COLOR, "w-6")} size={24} />
    </Button>
  );
}
