import classNames from "classnames";
import React from "react";
import { SECONDARY_TEXT_COLOR_DIM } from "~src/ui/classNames";

export function Footer({ center = false }: { center?: boolean }) {
  return (
    <div
      className={classNames("p-2 text-sm", SECONDARY_TEXT_COLOR_DIM, {
        ["text-end"]: !center,
        ["text-center"]: center,
      })}
    >
      <span className="opacity-50"> Linkila Technologies Inc. 🇨🇦</span>
      <br />
      <a href={`mailto:${window.SUPPORT_EMAIL}`}>Contact support</a>
    </div>
  );
}
