import { graphql } from "./gql";

export const IdentityViewerFrag = graphql(`
  fragment IdentityViewer on Viewer {
    id
    user {
      id
      username
      organizations {
        id
        name
        viewerMembership
      }
    }
  }
`);
