import React from "react";
import cx from "classnames";
import { useLocation, Location, Link } from "react-router-dom";
import {
  HiOutlineArrowRightOnRectangle as ArrowRightOnRectangleIcon,
  HiOutlineArrowDownOnSquareStack as ArrowDownOnSquareStackIcon,
  HiOutlineArrowLeftOnRectangle as LogoutIcon,
} from "react-icons/hi2";
import {
  BASE_LAYER,
  BASE_LAYER_HOVER,
  SECONDARY_TEXT_COLOR_DIM,
  SECONDARY_TEXT_COLOR_HILIGHT,
} from "~src/ui/classNames";
import { GlobalNavContext, GlobalNavToggleButton } from "./context";
import logoURL from "~/assets/LogoText.png";
import { DarkModeSettings } from "~src/ui/darkModeToggle";
import { Button } from "~src/ui/button";
import { useIdentityContext } from "~src/identity/context";

type GlobalNavItem = {
  icon: React.ReactNode;
  title: string;
  to: string;
  isActive: (location: Location) => boolean;
};

const GLOBAL_NAV_ITEMS: GlobalNavItem[] = [
  {
    icon: <ArrowRightOnRectangleIcon size={24} />,
    title: "Migrations",
    to: "/migrations",
    isActive: (location) => location.pathname.startsWith("/migrations"),
  },
  {
    icon: <ArrowDownOnSquareStackIcon size={24} />,
    title: "Backups",
    to: "/backups",
    isActive: (location) => location.pathname.startsWith("/backups"),
  },
];

function NavItem({ item }: { item: GlobalNavItem }) {
  const { isCollapsed } = React.useContext(GlobalNavContext);
  const location = useLocation();
  const isActive = item.isActive(location);
  return (
    <Link
      className={cx(BASE_LAYER_HOVER, "flex items-center rounded py-2", {
        "flex-col gap-y-2 ": isCollapsed,
        "gap-x-2 py-4 px-2": !isCollapsed,
        [SECONDARY_TEXT_COLOR_HILIGHT]: isActive,
        [SECONDARY_TEXT_COLOR_DIM]: !isActive,
      })}
      to={item.to}
    >
      <span className="w-6 shrink-0">{item.icon}</span>
      <span className={cx({ "text-sm": isCollapsed })}>{item.title}</span>
    </Link>
  );
}

function GlobalNavMenu() {
  return (
    <div className="flex flex-col mt-4 mx-2 shrink-0">
      {GLOBAL_NAV_ITEMS.map((item) => {
        return <NavItem item={item} key={item.to} />;
      })}
    </div>
  );
}

export function GlobalNav() {
  const { logout } = useIdentityContext();
  const { isCollapsed } = React.useContext(GlobalNavContext);
  return (
    <div
      className={cx(
        "rounded w-24 h-screen shrink-0 transition transition-width ease-in-out duration-200",
        "fixed shadow-md dark:shadow-neutral-800 block z-50",
        "sm:static sm:shadow-none",
        {
          "w-36": !isCollapsed,
          "left-0": !isCollapsed,
          "left-[-9999px]": isCollapsed,
        },
        BASE_LAYER
      )}
    >
      <div className="flex flex-col min-h-screen sm:h-full">
        <div className="w-full flex justify-center mt-3 shrink-0">
          <div className="hidden sm:block">
            <GlobalNavToggleButton />
          </div>
          <img
            className="h-auto w-28 sm:hidden block mt-2 dark:grayscale dark:brightness-150"
            src={logoURL}
            alt={`Logo of ${window.SITE_NAME}`}
          />
        </div>
        <GlobalNavMenu />
        <div className="grow"></div>
        <div className={cx(SECONDARY_TEXT_COLOR_DIM)}>
          <Button
            variant="transparent"
            className={cx("block text-center m-auto mb-2 font-normal")}
            onClick={logout}
          >
            <LogoutIcon size={16} title="Logout" />
          </Button>
        </div>
        <div className={cx(SECONDARY_TEXT_COLOR_DIM)}>
          <DarkModeSettings />
        </div>
        <div
          className={cx(
            "text-sm w-full py-2",
            "text-center select-none",
            BASE_LAYER,
            SECONDARY_TEXT_COLOR_DIM
          )}
        >
          v{window.VERSION}
        </div>
      </div>
    </div>
  );
}
